import { Button, Code, Stack, Text, TextInput } from '@mantine/core';
import {
  IconDashboard,
  IconLayoutDashboardFilled,
  IconLogout,
  IconSearch,
  IconSettingsFilled,
  IconUsersGroup,
} from '@tabler/icons-react';
import { getRouteApi, Link, LinkProps } from '@tanstack/react-router';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './app-navbar.module.css';

import { useAuth } from 'data-access-auth';
import { LangSelect } from 'ui';

const route = getRouteApi('/_app');

type Link = {
  label: string;
  to: LinkProps['to'];
  icon: typeof IconDashboard;
};

export function AppNavbar() {
  const navigate = route.useNavigate();
  const { logout } = useAuth();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');
  const { t } = useTranslation();

  const links: Link[] = [
    {
      to: '/dashboard',
      label: t('app.nav.dashboard'),
      icon: IconLayoutDashboardFilled,
    },
    {
      to: '/accounts',
      label: t('app.nav.accounts'),
      icon: IconUsersGroup,
    },
    {
      to: '/settings',
      label: t('app.nav.settings'),
      icon: IconSettingsFilled,
    },
  ];

  const shouldFilterOptions = !links.some((item) => item.label === value);
  const filteredLinks = shouldFilterOptions
    ? links.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase().trim())
      )
    : links;

  const handleLogout = () => {
    if (window.confirm('Are you sure you want to logout?')) {
      logout();
      navigate({ to: '/' });
    }
  };

  // listen to keyboard and focus search input on ctrl + /
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === '/' && event.ctrlKey) {
        searchInputRef.current?.focus();
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <Stack className={classes.container} justify="space-between">
      <Stack gap="xs">
        <TextInput
          classNames={{ root: classes.search, input: classes.searchInput }}
          color="dark"
          leftSection={<IconSearch />}
          onChange={(event) => setValue(event.currentTarget.value)}
          placeholder={t('app.nav.searchPlaceholder')}
          radius="md"
          ref={searchInputRef}
          rightSection={<Code className={classes.searchCode}>Ctrl + /</Code>}
          rightSectionWidth={70}
          styles={{ section: { pointerEvents: 'none' } }}
          value={value}
          variant="filled"
        />
        {filteredLinks.map((link) => (
          <Link className={classes.navlink} key={link.to} to={link.to}>
            <link.icon className={classes.linkIcon} stroke={2} />
            <Text component="span" truncate="end">
              {link.label}
            </Text>
          </Link>
        ))}
      </Stack>
      <Stack gap="xs">
        <Stack align="center" gap="xs" hiddenFrom="sm">
          <LangSelect />
        </Stack>
        <Button
          color="dark"
          justify="start"
          leftSection={<IconLogout />}
          onClick={handleLogout}
        >
          {t('auth.logout')}
        </Button>
      </Stack>
    </Stack>
  );
}

export default AppNavbar;
