import { rem } from '@mantine/core';
import { ComponentPropsWithoutRef } from 'react';

interface FrFlagIconProps extends ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}
export const FrFlagIcon = (props: FrFlagIconProps) => {
  return (
    <svg
      aria-label="France's flag"
      className={props.className}
      style={{
        width: rem(props.size),
        height: rem(props.size),
        ...props.style,
      }}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="a">
        <circle cx="256" cy="256" fill="#fff" r="256" />
      </mask>
      <g mask="url(#a)">
        <path d="M167 0h178l25.9 252.3L345 512H167l-29.8-253.4z" fill="#eee" />
        <path d="M0 0h167v512H0z" fill="#0052b4" />
        <path d="M345 0h167v512H345z" fill="#d80027" />
      </g>
    </svg>
  );
};

export default FrFlagIcon;
