// Sentry initialization should be imported first!
import './instrument';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import { router } from './router';

import { IAuthContext, AuthProvider, useAuth } from 'data-access-auth';
// Import the generated route tree

import './i18n';

// Create a new router instance

const queryClient = new QueryClient();

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }

  interface RouteContext {
    auth: IAuthContext;
  }
}

function InnerApp() {
  const auth = useAuth();
  return <RouterProvider context={{ auth }} router={router} />;
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <AuthProvider>
        <InnerApp />
      </AuthProvider>
      {/* </Suspense> */}
    </QueryClientProvider>
  );
}

// Render the app
const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}
