import { Box } from '@mantine/core';
import { useRouter } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import LoginForm from '../../ui/login-form/login-form';

import styles from './login-page.module.css';

import { getHTTPError } from 'data-access';
import { useAuth } from 'data-access-auth';

type LoginFormOnSubmit = React.ComponentProps<typeof LoginForm>['onSubmit'];

/* eslint-disable-next-line */
export interface LoginPageProps {}

export function LoginPage(props: LoginPageProps) {
  const router = useRouter();
  const { i18n } = useTranslation();
  const { login } = useAuth();

  const handleSubmit: LoginFormOnSubmit = (values) => {
    login.mutate({ email: values.email, password: values.password });
  };

  useEffect(() => {
    if (login.isSuccess) {
      router.invalidate();
    }
  }, [login.isSuccess, router]);

  return (
    <div className={styles['container']}>
      <Box className={styles.formContainer}>
        <LoginForm
          error={getHTTPError(login.error)}
          isError={login.isError}
          isFetching={login.isPending}
          onSubmit={handleSubmit}
          withIcons
        />
      </Box>
    </div>
  );
}

export default LoginPage;
