import { createRouter } from '@tanstack/react-router';

import { routeTree } from './routeTree.gen';

import { IAuthContext } from 'data-access-auth';

export const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  context: {
    auth: undefined! as IAuthContext, // This will be set after we wrap the app in an AuthProvider
  },
});
