import ky from 'ky';

import getAccessToken from '../utils/get-access-token';

export const publicClient = ky.extend({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - import is used by vite
  prefixUrl: import.meta.env.VITE_API_URL,
});

export const privateClient = publicClient.extend({
  hooks: {
    beforeRequest: [
      (request) => {
        const accessToken = getAccessToken();

        if (accessToken) {
          request.headers.set('Authorization', `Token ${accessToken}`);
        }
      },
    ],
  },
});
