import * as React from 'react';

import useLoggedUser from '../hooks/use-logged-user/logged-user';
import useLogin from '../hooks/use-login/login';

export interface IAuthContext {
  isAuthenticated: boolean;
  login: ReturnType<typeof useLogin>;
  logout: () => Promise<void>;
  user?: { user: string };
}

const AuthContext = React.createContext<IAuthContext | null>(null);

const tokenKey = 'tansack.auth.token';

function getStoredToken() {
  return localStorage.getItem(tokenKey);
}

function setStoredToken(token: string | null) {
  if (token) {
    localStorage.setItem(tokenKey, token);
  } else {
    localStorage.removeItem(tokenKey);
  }
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = React.useState<string | null>(getStoredToken());
  const isAuthenticated = !!token;

  const login = useLogin({
    onSuccess: (data) => {
      setToken(data.token);
      setStoredToken(data.token);
    },
  });

  const logout = React.useCallback(async () => {
    setStoredToken(null);
    setToken(null);
  }, []);

  React.useEffect(() => {
    setToken(getStoredToken());
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
