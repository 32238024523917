import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import 'mantine-react-table/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/charts/styles.css';
import '../styles.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import { MantineProvider, createTheme, rem } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRootRoute, Outlet } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import 'dayjs/locale/fr';
import 'dayjs/locale/ro';
import 'dayjs/locale/hu';

dayjs.locale('fr');

const theme = createTheme({
  primaryColor: 'dark',
  defaultRadius: 'md',
  spacing: {
    xxs: rem(8),
  },
});

export const Route = createRootRoute({
  component: RootComponent,
});

function RootComponent() {
  const { i18n } = useTranslation();

  return (
    <>
      <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
      <TanStackRouterDevtools position="bottom-right" />
      <MantineProvider theme={theme}>
        <Notifications />
        <ModalsProvider>
          <DatesProvider
            settings={{
              locale: i18n.language,
            }}
          >
            <Outlet />
          </DatesProvider>
        </ModalsProvider>
      </MantineProvider>
    </>
  );
}
