import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_TableOptions,
} from 'mantine-react-table';
import { create, StoreApi, UseBoundStore } from 'zustand';

type WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never;

const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(
  _store: S
) => {
  const store = _store as WithSelectors<typeof _store>;
  store.use = {};
  for (const k of Object.keys(store.getState())) {
    (store.use as any)[k] = () => store((s) => s[k as keyof typeof s]);
  }

  return store;
};

enum TablesEnum {
  users = 'users',
  accounts = 'accounts',
  plots = 'plots',
  advices = 'advices',
  forms = 'forms',
  results = 'results',
}

export type Tables = keyof typeof TablesEnum;

type BasicState = {
  pagination: MRT_PaginationState;
  sorting: MRT_SortingState;
  filters: MRT_ColumnFiltersState;
};

export type State = {
  [key in Tables]: BasicState;
};

type Actions = {
  setPagination: (table: Tables, pagination: MRT_PaginationState) => void;
  setSorting: (table: Tables, sorting: MRT_SortingState) => void;
  setFilters: (table: Tables, filters: MRT_ColumnFiltersState) => void;
};

const genInitialState = (): State => {
  const tables = Object.keys(TablesEnum) as Tables[];
  const initialState: State = {} as State;
  tables.forEach((table) => {
    let defaultSorting: MRT_SortingState = [];

    switch (table) {
      case TablesEnum.users:
        defaultSorting = [{ id: 'last_name', desc: true }];
        break;
      case TablesEnum.accounts:
        defaultSorting = [{ id: 'account_name', desc: true }];
        break;
      case TablesEnum.plots:
        defaultSorting = [{ id: 'farm__name', desc: true }];
        break;
      case TablesEnum.forms:
        defaultSorting = [
          {
            desc: true,
            id: 'field.designator',
          },
        ];
        break;
      case TablesEnum.advices:
        defaultSorting = [
          {
            desc: true,
            id: 'designator',
          },
        ];
        break;
    }

    initialState[table] = {
      pagination: {
        pageIndex: 0,
        pageSize: 25,
      },
      sorting: defaultSorting,
      filters: [],
    };
  });
  return initialState;
};

export const useFiltersStoreBase = create<State & Actions>((set, store) => ({
  ...genInitialState(),
  setPagination: (table, pagination) =>
    set((state) => ({ [table]: { ...state[table], pagination } })),
  setSorting: (table, sorting) =>
    set((state) => ({ [table]: { ...state[table], sorting } })),
  setFilters: (table, filters) =>
    set((state) => ({ [table]: { ...state[table], filters } })),
}));

export const useFiltersStore = createSelectors(useFiltersStoreBase);

export const usePagination = (table: Tables) => {
  const store = useFiltersStore((state) => state[table]);
  const setPagination = useFiltersStore((state) => state.setPagination);

  return [
    store.pagination,
    (pagination: MRT_PaginationState) => setPagination(table, pagination),
  ] as const;
};

export const useSorting = (table: Tables) => {
  const store = useFiltersStore((state) => state[table]);
  const setSorting = useFiltersStore((state) => state.setSorting);

  return [
    store.sorting,
    (sorting: MRT_SortingState) => setSorting(table, sorting),
  ] as const;
};

export const useColFilters = (table: Tables) => {
  const store = useFiltersStore((state) => state[table]);
  const setFilters = useFiltersStore((state) => state.setFilters);

  return [
    store.filters,
    (filters: MRT_ColumnFiltersState) => setFilters(table, filters),
  ] as const;
};

export const useFiltersTableOptions = <T extends Record<string, any>>(
  table: Tables
): MRT_TableOptions<T> => {
  const [pagination, setPagination] = usePagination(table);
  const [sorting, setSorting] = useSorting(table);
  const [columnFilters, setColumnFilters] = useColFilters(table);

  return {
    manualFiltering: true, //turn off client-side filtering
    onColumnFiltersChange: (state) =>
      setColumnFilters(
        typeof state === 'function' ? state(columnFilters) : state
      ), //hoist internal columnFilters state to your state

    enableMultiSort: false,
    manualSorting: true,
    onSortingChange: (state) =>
      setSorting(typeof state === 'function' ? state(sorting) : state),

    manualPagination: true,
    onPaginationChange: (state) =>
      setPagination(typeof state === 'function' ? state(pagination) : state), //hoist pagination state to your state when it changes internally
    mantinePaginationProps: {
      showRowsPerPage: false,
    },

    state: {
      pagination,
      sorting,
      columnFilters,
    },
  } as MRT_TableOptions<T>;
};
