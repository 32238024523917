import * as Sentry from '@sentry/react';

import { router } from './router';

Sentry.init({
  dsn: 'https://2ffb9760bb85686f42cab4d437ea5319@o4507627694522368.ingest.de.sentry.io/4507697615470672',
  integrations: [
    Sentry.tanstackRouterBrowserTracingIntegration(router),
    // eslint-disable-next-line import/namespace
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    /^\//,
    /^https:\/\/template.raphaelgimenez.fr/,
    // /localhost/,
  ],

  // Capture Replay for 0% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});
