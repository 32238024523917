import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { authenticationAPI, Credentials } from '../../apis/authentication-api';

type Params = UseMutationOptions<
  {
    token: string;
    user_id: number;
    email: string;
  },
  Error,
  Credentials
>;

export function useLogin(params: Params) {
  return useMutation({
    mutationFn: authenticationAPI.login,
    ...params,
  });
}

export default useLogin;
