import {
  Group,
  Burger,
  ThemeIcon,
  Title,
  Text,
  Stack,
  Box,
} from '@mantine/core';
import { IconVersionsFilled } from '@tabler/icons-react';

import classes from './app-header.module.css';

import { LangSelect } from 'ui';

interface AppHeaderProps {
  opened: boolean;
  toggle: () => void;
}

export function AppHeader({ opened, toggle }: AppHeaderProps) {
  return (
    <Group
      className={classes.container}
      h="100%"
      justify="space-between"
      px="md"
    >
      <Group>
        <Burger
          hiddenFrom="sm"
          lineSize={2}
          onClick={toggle}
          opened={opened}
          size="sm"
        />
        <Group>
          <ThemeIcon size={50} variant="light">
            <IconVersionsFilled style={{ width: '70%', height: '70%' }} />
          </ThemeIcon>
          <Stack gap={0}>
            <Title m={0} order={1}>
              graph
            </Title>
            <Text component="span" mt={-10}>
              .template
            </Text>
          </Stack>
        </Group>
      </Group>
      <Box visibleFrom="sm">
        <LangSelect />
      </Box>
    </Group>
  );
}

export default AppHeader;
