import { publicClient } from 'data-access';

export interface Credentials {
  email: string;
  password: string;
}

interface lostPasswordDto {
  email: string;
  lang: string;
}

interface ResetPasswordDto {
  password: string;
  token: string;
}

export const authenticationAPI = {
  login: async (credentials: Credentials) => {
    // return publicClient
    //   .post('token/', {
    //     json: credentials,
    //   })
    //   .json<{
    //     token: string;
    //     user_id: number;
    //     email: string;
    //   }>();

    await new Promise((resolve) => setTimeout(resolve, 700));
    return {
      token: 'test',
      user_id: 1,
      email: 'test@mail.com',
    };
  },
  getLoggedUser: async () => {
    // return publicClient.get('me').json();
    // await 5 seconds
    await new Promise((resolve) => setTimeout(resolve, 310));
    return {
      user: 'Tom',
    };
  },
  lostPassword: async (params: lostPasswordDto) => {
    try {
      const res = await publicClient
        .post(`password_reset/`, {
          json: params,
        })
        .json();

      return res;
    } catch (error: any) {
      const err = await error.response.text();
      throw new Error(err);
    }
  },
  resetPassword: (params: ResetPasswordDto) => {
    return publicClient
      .post(`password_reset/confirm/`, {
        json: params,
      })
      .json();
  },
};
